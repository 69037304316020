import { render, staticRenderFns } from "./main-layout.vue?vue&type=template&id=ef55ead2&scoped=true"
import script from "./main-layout.ts?vue&type=script&lang=js&external"
export * from "./main-layout.ts?vue&type=script&lang=js&external"
import style0 from "./main-layout.vue?vue&type=style&index=0&id=ef55ead2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef55ead2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VMain})
