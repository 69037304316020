export default class DietHelper {
    private static dietDict = [
        {value: 1, text: "Bezglutenowa"},
         {value: 2, text:"Wegetariańska"},
         { value: 4, text:"Wegańska"},
         { value: 8, text:"Bezlaktozowa"} ]

    public static getDietName(value: number): string {
        return this.dietDict.find(x => x.value == value)!.text;
    }

    public static getDietsDict(): {}[] {
        return this.dietDict;
    }
}